import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Drink } from '../shared/models/drink';
import { Order } from '../shared/models/order';
import { Supporter } from '../shared/models/supporter';
import { LmsService } from '../shared/services/lms.service';

@Component({
  selector: 'app-neworder',
  templateUrl: './neworder.component.html',
  styleUrls: ['./neworder.component.scss']
})
export class NeworderComponent implements OnInit {
  public supporter: Supporter = new Supporter();
  public drinks: Drink[] = []
  public order: Order = new Order();

  constructor(public lmsService: LmsService, private router: Router, private _snackBar: MatSnackBar) { }


  ngOnInit(): void {
    this.supporter = this.lmsService.supporter;
    this.fillDrinks();
  }

  public fillDrinks() {
    let beer = new Drink("Cristal", 0.5);
    let coke = new Drink("Cola", 0.85);
    let cokez = new Drink("Cola Zero", 0.85);
    let whitew = new Drink("Gin-tonic", 3.50);
    let rosew = new Drink("Wodka-sprite", 3.10);
   /*  let liefmans = new Drink("Kriek Liefmans", 1.25);
    let kasteel = new Drink("Kasteel Rouge", 1.5);
    let karmeliet = new Drink("Karmeliet", 1.5);
    let pizza = new Drink("Hamburger", 2.5);
    let hotdog = new Drink("Hotdog", 2.5);
    let chipsp = new Drink("Lays Paprika", 0.5);
    let chipsz = new Drink("Lays Zout", 0.5); */

    this.drinks.push(beer);
/*     this.drinks.push(liefmans);
    this.drinks.push(karmeliet);
    this.drinks.push(kasteel); */
    this.drinks.push(whitew);
    this.drinks.push(rosew);
    this.drinks.push(coke);
    this.drinks.push(cokez);
    /*  this.drinks.push(pizza);
   this.drinks.push(hotdog);
    this.drinks.push(chipsp);
    this.drinks.push(chipsz); */
    
  }

  public addCount(index: number) {
    this.drinks[index].count++;
  }

  public removeCount(index: number) {
    if (this.drinks[index].count > 0) {
      this.drinks[index].count--;
    }
  }

  public getOrderTotal() {
    let total = 0;

    this.drinks.forEach(drink => {
      if (drink.count > 0) {
        total += (drink.count * drink.price);
      }
    });

    return total;
  }

  public confirmOrder() {
    if (this.getOrderTotal() > 0) {
      let newOrder = new Order();
      newOrder.description = "";
  
      newOrder.supporter = this.lmsService.supporter;
      newOrder.supporterId = this.lmsService.supporter.id;
      newOrder.amount = 0;
  
      this.drinks.forEach(drink => {
        if (drink.count > 0) {
          newOrder.amount += (drink.count * drink.price);
          newOrder.description += (drink.count + " x " + drink.name + " - ");
        }
      });
  
      this.lmsService.postOrder(newOrder).subscribe(data => {
        this.clearCount();
        this._snackBar.open(
          "Bestelling succesvol doorgegeven!",
          "×",
          { panelClass: "success", verticalPosition: "top", duration: 3000 }
        );
        this.router.navigate(["/home"]);
      }); 
    } else {
       this._snackBar.open(
        "Je hebt helemaal niets besteld :(",
        "×",
        { verticalPosition: "top", duration: 3000 }
      );
    }
    

  }

  public clearCount() {
    this.drinks.forEach(drink => {
      drink.count = 0;
    });
  }

  public back() {
    this.router.navigate(["/home"]);
  }

}
