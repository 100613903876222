import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from "./material/material.module";
import { FlexLayoutModule } from '@angular/flex-layout';
import { LoginComponent } from './login/login.component';
import { FormsModule } from "@angular/forms";
import { HomeComponent } from './home/home.component';
import { NeworderComponent } from './neworder/neworder.component';
import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { MainInterceptor } from './shared/interceptors/main-interceptor';
import { NgxSpinnerModule } from 'ngx-spinner';
import { OrdersComponent } from './orders/orders.component';
import { SongComponent } from './song/song.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    NeworderComponent,
    OrdersComponent,
    SongComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    NgxSpinnerModule
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: MainInterceptor, multi: true },],
  bootstrap: [AppComponent]
})
export class AppModule { }
