<div class="title" fxLayout="row" fxLayoutAlign="space-around center">
    <button
      class="btn"
      mat-raised-button
      (click)="back()"
    >
    <mat-icon>arrow_back</mat-icon>
      Home
    </button>
</div>

<div fxLayout="column" fxLayoutAlign="space-around center">
    <p class="song">O dierbaar België <br/>
        O heilig land der Vaad'ren <br/>
        Onze ziel en ons hart zijn u gewijd.<br/>
        Aanvaard ons kracht en het bloed van onze ad'ren,<br/>
        Wees ons doel in arbeid en in strijd.<br/>
        Bloei, o land, in eendracht niet te breken;<br/>
        Wees immer u zelf en ongeknecht,<br/>
        Het woord getrouw, dat g' onbevreesd moogt spreken:<br/>
        Voor Vorst, voor Vrijheid en voor Recht.<br/>
        Het woord getrouw, dat g' onbevreesd moogt spreken:<br/>
        Voor Vorst, voor Vrijheid en voor Recht.<br/>
        Voor Vorst, voor Vrijheid en voor Recht.<br/>
        Voor Vorst, voor Vrijheid en voor Recht.</p>
</div>
