import { HttpHeaders, HttpErrorResponse, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { Supporter } from "../models/supporter";
import { catchError } from "rxjs/operators";
import { Order } from "../models/order";


const httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json-patch+json",
    }),
  };

@Injectable({
    providedIn: "root",
  })
  export class LmsService {
      public supporter: Supporter;

      private supportersUrl = environment.lmsApi + "Supporters/";
      private ordersUrl = environment.lmsApi + "Orders/";

      constructor(private http: HttpClient) {}

      public loginNewSupporter(supporter: Supporter) {
          this.supporter = supporter;
          localStorage.setItem('supporterName', supporter.name);
          localStorage.setItem('supporterId', supporter.id + "");
      }

      public postOrder(order: Order): Observable<Order> {
        return this.http
          .post<Order>(this.ordersUrl, order, httpOptions)
          .pipe(catchError(this.handleError));
      }

      public postSupporter(supporter: Supporter): Observable<Supporter> {
        return this.http
          .post<Supporter>(this.supportersUrl, supporter, httpOptions)
          .pipe(catchError(this.handleError));
      }

      public getSupporterById(id: number): Observable<Supporter> {
        return this.http
          .get<Supporter>(this.supportersUrl + id, httpOptions)
          .pipe(catchError(this.handleError));
      }

      private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
          console.error("Fout:", error.error.message);
        } else {
          console.error(
            `Backend returned code Deze code voor handling ${error.status}` +
              ` body was: ${error.error}`
          );
        }
    
        return throwError("Er is iets fout gegaan");
      }
  }