import { Supporter } from "./supporter";

export class Order {
    public id: number;
    public supporterId: number;
    public supporter: Supporter;
    public amount: number;
    public description: string;
  
    constructor() {}
  }
  