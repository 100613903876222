<div class="login-container" fxLayout="column" fxLayoutAlign="space-around center">
    
    <div id="login" fxLayout="column" fxLayoutAlign="space-around center"> 
      <img src="../../assets/img/halo.jpg" id="image"/>
        <form #loginForm="ngForm" id="loginform" fxLayout="column">
            <mat-form-field id="namefield" appearance="outline">
              <mat-label>Voornaam</mat-label>
              <input matInput [(ngModel)]="this.supporter.name" name="name" type="text"/>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>E-mail</mat-label>
                <input matInput [(ngModel)]="this.supporter.email" name="email" type="email" />
              </mat-form-field>
            <button
              mat-raised-button
              aria-label="login"
              color="primary"
              (click)="login()"
            >
              Start met drinken
            </button> 
          </form>
    </div>

</div>