<div fxLayout="column" fxLayoutAlign="space-around center">
    <h2 class="title">Welkom, {{this.supporter.name}}!</h2>

            <button
              class="btn"
              mat-raised-button
              aria-label="login"
              color="primary"
              (click)="navigate('neworder')"
            >
              Nieuwe bestelling
              <mat-icon>add</mat-icon>
            </button> 

            <button
            class="btn add-top"
              mat-raised-button
              aria-label="login"
              color="primary"
              (click)="navigate('orders')"
            >
              Overzicht bestellingen
              <mat-icon>list</mat-icon>
            </button>  

            <img src="../../assets/img/halo.jpg" class="home-banner" />
<!-- 
            <button
            class="btn add-top"
              mat-raised-button
              aria-label="login"
              color="primary"
              (click)="navigate('song')"
            >
            Brabançonne 
              <mat-icon>queue_music</mat-icon>
            </button>  -->

</div>


