import { Order } from "./order";

export class Supporter {
    public id: number;
    public name: string;
    public email: string;
    public orders: Order[];
  
    constructor() {}
  }
  