import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Supporter } from '../shared/models/supporter';
import { LmsService } from '../shared/services/lms.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public supporter: Supporter = new Supporter();

  constructor(public lmsService: LmsService, private router: Router) { }

  ngOnInit(): void {
    
    if (localStorage.getItem("supporterId")) {
      let id: number = +localStorage.getItem("supporterId");
      this.lmsService.getSupporterById(id).subscribe(data => {
        this.lmsService.loginNewSupporter(data);
        this.router.navigate(["/home"])
      });
      
    }
  }

  public login() {
    this.lmsService.postSupporter(this.supporter).subscribe(data => {
      this.lmsService.loginNewSupporter(data);
      this.router.navigate(["/home"]);
    });

  /*   this.lmsService.loginNewSupporter(this.supporter);
      this.router.navigate(["/home"]); */
  }

}
