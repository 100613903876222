import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Supporter } from './shared/models/supporter';
import { LmsService } from './shared/services/lms.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'LMSEK';

  constructor(public lmsService: LmsService, private router: Router){}

  ngOnInit() {
    if (localStorage.getItem("supporterId")) {
      let newSup = new Supporter();
      let id: number = +localStorage.getItem("supporterId");
      newSup.name = localStorage.getItem('supporterName');
      newSup.id = id;
      this.lmsService.supporter = newSup;
      this.router.navigate(["/home"])
    } else {
      this.router.navigate(["/login"]);
    }

    
  }
}
