<div class="title" fxLayout="row" fxLayoutAlign="space-around center">
    <button
      class="btn"
      mat-raised-button
      (click)="back()"
      fxFlex="30"
    >
    <mat-icon>arrow_back</mat-icon>
      Home
    </button>
    <h2 fxFlex="60">Totaal: €{{this.getTotal() | number : '1.2-2'}}</h2> 
</div>

<div fxLayout="column" fxLayoutAlign="space-around stretch">

    <mat-card *ngFor="let order of this.supporter.orders; let i = index" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxFlex="50">
            <p>{{order.description}}</p>
        </div>

        <div fxFlex="50" fxLayout="row" fxLayoutAlign="space-around center">
           <p>€{{order.amount | number : '1.2-2'}}</p>
        </div>
    </mat-card>

</div>

