import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Supporter } from '../shared/models/supporter';
import { LmsService } from '../shared/services/lms.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public supporter: Supporter = new Supporter();

  constructor(public lmsService: LmsService, private router: Router) { }

  ngOnInit(): void {
    this.supporter = this.lmsService.supporter;
  }

  public navigate(route:string) {
    this.router.navigate([route]);
  }

}
