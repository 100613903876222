export class Drink {
    public name: string;
    public price: number;
    public count: number;

    constructor(name: string, price: number) {
        this.name = name;
        this.price = price;
        this.count = 0;
    }
    
}