import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Supporter } from '../shared/models/supporter';
import { LmsService } from '../shared/services/lms.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {
  public supporter: Supporter = new Supporter();

  constructor(private router: Router, public lmsService: LmsService) { }

  ngOnInit(): void {
    this.supporter.orders = [];
    if (localStorage.getItem("supporterId")) {
      let id: number = +localStorage.getItem("supporterId");
      this.lmsService.getSupporterById(id).subscribe(data => {
        this.supporter = data;
        console.log(this.supporter);
      });
    }
  }

  public getTotal() {
    let total = 0;

    this.supporter.orders.forEach(order => {
      total += order.amount;
    });

    return total;
  }

  public back() {
    this.router.navigate(["/home"]);
  }

}
