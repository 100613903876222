import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from "./login/login.component";
import { HomeComponent } from "./home/home.component";
import { NeworderComponent } from "./neworder/neworder.component";
import { OrdersComponent } from "./orders/orders.component";
import { SongComponent } from './song/song.component';

const routes: Routes = [
  {path: '', component: LoginComponent},
  {path: 'login', component: LoginComponent},
  {path: 'home', component: HomeComponent},
  {path: 'neworder', component: NeworderComponent},
  {path: 'orders', component: OrdersComponent},
  {path: 'song', component: SongComponent},
  { path: "**", component: HomeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
