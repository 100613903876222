<div class="title" fxLayout="column" fxLayoutAlign="space-around center">
    <h2>Wat ga je drinken, {{this.supporter.name}}?</h2> 
</div>

<div fxLayout="column" fxLayoutAlign="space-around stretch">

    <mat-card *ngFor="let drink of this.drinks; let i = index" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxFlex="50">
            <p>{{drink.name}} - €{{drink.price}}</p>
        </div>

        <div fxFlex="50" fxLayout="row" fxLayoutAlign="space-around center">
            <button mat-mini-fab (click)="removeCount(i)">
                <mat-icon>remove</mat-icon>
              </button>
              <p>{{drink.count}}</p>
              <button mat-mini-fab (click)="addCount(i)">
                <mat-icon>add</mat-icon>
              </button>
        </div>
    </mat-card>

</div>

<div fxLayout="column" fxLayoutAlign="space-around center">
    <h3>Totaal: €{{this.getOrderTotal() | number : '1.2-2'}}</h3>

    <div class="buttons-div" fxLayout="row" fxLayoutAlign="space-between center">
      <button
      class="btn"
      mat-raised-button
      aria-label="login"
      (click)="back()"
      fxFlex="48"
    >
    <mat-icon>arrow_back</mat-icon>
      Home
    </button>  <button
    class="btn"
    mat-raised-button
    aria-label="login"
    fxFlex="48"
    color="primary"
    (click)="confirmOrder()"
  >
    Bevestigen
    <mat-icon>check</mat-icon>
  </button> 
    </div>
     
</div>


